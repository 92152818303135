<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-4">
      <v-card-title>Vision Calendario</v-card-title>

      <v-dialog
        scrollable
        v-model="showEditRecurring"
        max-width="350px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="text-h6">Editar evento recurrente</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="d-flex justify-left">
                <v-radio-group v-model="recurringStatus" column>
                  <v-radio label="Esta Tarea" value="0"></v-radio>
                  <v-radio
                    label="Esta Tareas y las siguientes"
                    value="1"
                  ></v-radio>
                  <v-radio label="Todas las Tareas" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditRecurringEvent">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isRecurrentSaving"
              @click="onSaveForRecurrency"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="this.deletedId > 0"
        scrollable
        v-model="showDeleteEvent"
        max-width="300px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="text-h6">Eliminar evento</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="d-flex justify-left">
                <v-radio-group v-model="deleteStatus" column>
                  <v-radio label="Esta Tarea" value="0"></v-radio>
                  <v-radio label="Todas las Tareas" value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeleteEvent">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isDeleting"
              @click="onDeleteEvent"
            >
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        scrollable
        v-model="showCustomRecurrence"
        max-width="400px"
        persistent
      >
        <v-form ref="form_custom_recurrence" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h6">Recurrencia personalizada</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="12" sm="4" md="4" lg="4" class="mr-1">
                    <span>Repite cada</span>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" class="d-flex p-0 mr-4">
                    <v-text-field
                      v-model="customRecurrence.interval"
                      type="number"
                      class="text-left"
                      @change="onChangeRecurrenceInterval"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" class="d-flex p-0">
                    <v-select
                      v-model="customRecurrence.freq"
                      :items="FREQ_ITEMS"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="customRecurrence.freq === 'days'">
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-radio-group
                      v-model="customRecurrence.isWeekend"
                      class="d-flex flex-row"
                    >
                      <v-radio label="Entre semana" value="1" row></v-radio>
                      <v-radio label="Todos los dias" value="2" row></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  v-else-if="customRecurrence.freq === 'weeks'"
                  class="align-center"
                >
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <span>Repetir en</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="p-0">
                    <v-select
                      v-model="customRecurrence.workingDays"
                      :items="WORKINGDAYS_ITEMS"
                      item-text="text"
                      item-value="value"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeCustomRecurrenceDialog"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveCustomRecurrence(customRecurrence)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog
        v-if="selected"
        scrollable
        v-model="showEdit"
        max-width="700px"
        persistent
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="`Nombre`"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="text-right"
                    v-if="editedIndex !== -1 && editedIndex"
                  >
                    <span
                      class="border border-dark blue darken-2 rounded-lg py-2 px-3 white--text text-body-1"
                      v-if="
                        isTaskOnTime(
                          selected.status,
                          selected.completedDate,
                          selected.task_endDate
                        )
                      "
                      >A TIEMPO</span
                    >
                    <span
                      class="border border-dark red darken-2 rounded-lg py-2 px-3 white--text text-body-1"
                      v-else
                      >CON RETRASO</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      clear-icon="mdi-close-circle"
                      label="Descripción"
                      row-height="15"
                      rows="5"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="selected?.static">
                  <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="selected.static.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                    >
                      <template v-if="selected.static.static" #label>
                        {{ selected.static.static.file }}
                      </template>
                    </v-file-input>
                    <v-btn
                      v-if="selected.static.static"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(selected.static.static)"
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ `${$t("Download")} ${$t("PDF File")}` }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.responsibleId"
                      v-bind:label="`Responsable`"
                      item-text="name"
                      item-value="id"
                      :items="user_list"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="`Marca`"
                      item-text="name"
                      item-value="id"
                      :items="brand_list"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.projectId"
                      v-bind:label="`Proyecto`"
                      item-text="name"
                      item-value="id"
                      :items="project_list"
                      @change="onChangedProject"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.dependencyId"
                      v-bind:label="`Dependencia`"
                      item-text="name"
                      item-value="id"
                      :loading="isDependenceLoading"
                      :items="dependence_list"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.type"
                      v-bind:label="`Tipo tarea`"
                      :items="TASK_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="selected.type === 'Recurrente'"
                  >
                    <v-text-field
                      :value="getRecurrenceDescription(selected.recurrence)"
                      v-bind:label="`Recurrencia`"
                      :append-outer-icon="'mdi-send'"
                      @click:append-outer="openCustomRecurrenceDialog"
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="selected.type === 'Recurrente'">
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="recurrence_startDate_menu"
                      v-model="select_recurrence_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.recurrence_startDate"
                          v-bind:label="`Fecha de inicio de recurrencia`"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.recurrence_startDate"
                        no-title
                        @input="select_recurrence_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="recurrence_endDate_menu"
                      v-model="select_recurrence_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.recurrence_endDate"
                          v-bind:label="
                            `Fecha de finalización de la recurrencia`
                          "
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.recurrence_endDate"
                        no-title
                        scrollable
                        @input="select_recurrence_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="24" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.priority"
                      v-bind:label="`Prioridad`"
                      :items="PRIORITY_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="24" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.status"
                      v-bind:label="`Estado`"
                      :items="STATUS_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-menu
                      ref="task_startDate_menu"
                      v-model="select_task_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.task_startDate"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          readonly
                          :rules="taskStartDateRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.task_startDate"
                        no-title
                        @input="select_task_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-menu
                      ref="task_endDate_menu"
                      v-model="select_task_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.task_endDate"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="taskEndDateRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.task_endDate"
                        no-title
                        scrollable
                        @input="select_task_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="selected.hour"
                      v-bind:label="`Hora`"
                      type="time"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="onDeleteClick(selected)"
                v-if="editedIndex !== -1 && editedIndex"
              >
                {{ $t("delete") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(selected)"
                :loading="isSaving"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form_search" v-model="valid" lazy-validation>
              <v-row style="align-items: center">
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.projectId"
                    label="Proyecto"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_projects"
                    :loading="isSearching['projects']"
                    @change="onChangeFilterMainCredentials('projects')"
                    :items="filtered_projects"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.responsibleId"
                    label="Responsable"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="title"
                    item-value="id"
                    :search-input.sync="search_responsible"
                    :loading="isSearching['responsible']"
                    :items="filtered_responsible"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    label="Tipo de tarea"
                    v-model="filterMainCredential.type"
                    :items="TASK_TYPES"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    label="Estado"
                    v-model="filterMainCredential.status"
                    :items="FILTERING_STATUS_TYPES"
                    item-text="text"
                    item-value="value"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.company"
                    label="Compañía"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_company"
                    @change="onChangeFilterMainCredentials('company')"
                    :loading="isSearching['company']"
                    :items="filtered_company"
                    hide-no-data
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-checkbox
                    label="solo usuarios activos"
                    v-model="isActive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-btn
                    color="blue darken-1"
                    class="mr-8"
                    @click="onClickFilter"
                  >
                    {{ $t("go") }}
                  </v-btn>
                  <v-btn color="primary" @click="onItemEdit">
                    {{ $t("newitem") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="calendar" id="calendario">
        <calendar-view
          :show-date="showDate"
          class="theme-default holiday-us-traditional holiday-us-official locale-en"
          :displayPeriodUom="displayPeriodUom"
          :displayPeriodCount="displayPeriodCount"
          :periodChangedCallback="periodChangedCallback"
          :startingDayOfWeek="startingDayOfWeek"
          :locale="`es`"
          :monthNameFormat="`long`"
          :currentPeriodLabel="`Hoy`"
          :events="calendarItems"
          :enableDragDrop="true"
          :showEventTimes="true"
          :doEmitItemMouseEvents="true"
          :eventTop="eventTop"
          :timeFormatOptions="timeFormatOptions"
          @drop-on-date="dropOnDate"
          @click-date="onClickDate"
          @click-event="onClickEvent"
          @item-mouseenter="item_mouseenter"
        >
          <calendar-view-header
            slot="header"
            slot-scope="t"
            :header-props="t.headerProps"
            @input="setShowDate"
          />
        </calendar-view>
      </div>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { logError, logInfo } from "@/utils.js";
import { downloadCSVFile } from "@/csv.js";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import moment from "moment";
import { mapGetters } from "vuex";
import download from "js-file-download";

// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
require("vue-simple-calendar/static/css/holidays-us.css");

export default {
  name: "Calendar",
  components: {
    CalendarView,
    CalendarViewHeader
  },
  data: function() {
    return {
      /**************** Calendar ************************/
      showDate: new Date(),
      displayPeriodUom: "month", // By default - "month" / "year", "week"
      displayPeriodUomItems: ["month", "year", "week"],
      displayPeriodCount: 1,
      enableDateSelection: true,
      startingDayOfWeek: 1, // Monday, Tue, Wed
      calendarItems: [],
      eventTop: "2.1em",
      timeFormatOptions: {
        // weekday: 'long', // Display full weekday names (e.g., 'Monday', 'Tuesday', etc.)
        // year: 'numeric', // Display the year in numeric format
        // month: 'long', // Display full month names (e.g., 'January', 'February', etc.)
        // day: 'numeric', // Display the day in numeric format
        hour: "numeric", // Display the hour
        minute: "numeric", // Display the minute
        hour12: true // Use a 12-hour clock format
      },

      STATUS_STYLES: {
        COMPLETED: "rgb(51, 182, 121)",
        PENDING: "#777272",
        DELAYED: "red",
        OVERDUE: "orange"
      },

      MULTIPLIER: 1000003,
      SUB_MULTIPLIER: 10007,

      /********** form-validation options **********/
      filter_main_credentials_fetch_url:
        "tasks/columna/getfiltermaincredentials",
      FILTERING_STATUS_TYPES: [
        { text: "A tiempo / Finalizada", value: "Completed" },
        { text: "A tiempo / Pendiente", value: "Pending" },
        { text: "Con retraso / Finalizada", value: "Overdue" },
        { text: "Con retraso / Pendiente", value: "Delayed" }
      ],
      STATUS_TYPES: [
        { text: "Finalizada", value: "Finalizada" },
        { text: "Pendiente", value: "Pendiente" }
      ],
      TASK_TYPES: [
        { text: "Puntual", value: "Puntual" },
        { text: "Recurrente", value: "Recurrente" }
      ],
      PRIORITY_TYPES: [
        { text: "Alta", value: "Alta" },
        { text: "Media", value: "Media" },
        { text: "Baja", value: "Baja" }
      ],

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "¡El tamaño del archivo debe ser inferior a 1 MB!";
          }
        }
        return true;
      },
      valid: true,
      filtered_brands: [],
      search_brands: null,
      filtered_projects: [],
      search_projects: null,
      filtered_responsible: [],
      search_responsible: null,
      filtered_company: [],
      search_company: null,
      isSearching: {},
      filterMainCredential: {
        brandId: [],
        status: [],
        type: [],
        projectId: [],
        date: null,
        isActiveUser: false,
        responsibleId: [],
        company: null
      },
      isActive: null,
      loadingInstance: null,

      /************************** Edit Dialog *************************/
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      tasksData: null,

      brand_list: null,
      project_list: null,
      user_list: null,
      dependence_list: null,

      select_task_startDate_menu: false,
      select_task_endDate_menu: false,

      select_recurrence_startDate_menu: false,
      select_recurrence_endDate_menu: false,

      isSaving: false,
      isDependenceLoading: false,

      showEditRecurring: false,
      isRecurrentSaving: false,
      bodyData: null,
      recurringStatus: "0",
      /************************ Delete Dialog *************************/
      showDeleteEvent: false,
      deleteStatus: "0",
      isDeleting: false,
      deletedId: -1,
      /************************* Custom Recurrence *******************************/
      showCustomRecurrence: false,
      customRecurrence: {},
      FREQ_ITEMS: [
        { text: "días", value: "days" },
        { text: "semanas", value: "weeks" },
        { text: "meses", value: "months" },
        { text: "años", value: "years" }
      ],
      WORKINGDAYS_ITEMS: [
        { text: "Domingo", value: "Sun" },
        { text: "Lunes", value: "Mon" },
        { text: "Martes", value: "Tue" },
        { text: "Miercoles", value: "Wed" },
        { text: "Jueves", value: "Thu" },
        { text: "Viernes", value: "Fri" },
        { text: "Sabado", value: "Sat" }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    taskStartDateRules() {
      return this.selected.projectId ? [v => !!v || "Required"] : [];
    },
    taskEndDateRules() {
      return this.selected.type === "Puntual" || this.selected.projectId
        ? [v => !!v || "Required"]
        : [];
    }
  },
  watch: {
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_projects(val) {
      this.searchValues("projects", val);
      // this.searchValues("responsible", "");
    },
    async search_responsible(val) {
      this.searchValues("responsible", val);
    },
    async isActive(val) {
      this.filterMainCredential.isActiveUser = val;
      this.searchValues("isActive", val);
    },
    async search_company(val) {
      this.searchValues("company", val);
      // this.searchValues("responsible", "");
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#calendario"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },
    setShowDate(d) {
      this.showDate = d;
    },
    periodChangedCallback() {
      // This function is invoked whenever the date changes
      this.getDataFromApi();
    },
    onClickDate(date, windowEvent) {
      //   console.log("***************************** date --- ", date);
    },
    selectedEndDate(endDate) {
      //   console.log("endDate ---", endDate);
    },
    item_mouseenter(element) {
      // console.log("item_mouseEnter --- ", element);
    },
    dropOnDate(calendarItem, date, windowEvent) {},
    async onClickEvent(calendarItem, windowEvent) {
      this.createSelectedModel(calendarItem);
      this.editedIndex = this.selected.id;
      if (this.selected.projectId)
        this.onChangedProject(this.selected.projectId);
      this.showEdit = true;
    },
    isTaskOnTime(status, completedDate, endDate) {
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (status === "Finalizada") return completedDate <= endDate;
      return today <= endDate;
    },
    convertDateToString(date) {
      return moment(new Date(date))
        .local()
        .format("YYYY-MM-DD");
    },
    createSelectedModel(calendarItem) {
      let id = calendarItem?.id;
      if (id) {
        let trackingIndex = id % this.SUB_MULTIPLIER;
        id = ((id - trackingIndex) / this.SUB_MULTIPLIER) % this.MULTIPLIER;
        let item = this.tasksData[id - 1];
        let model = {
          id: _.get(item, "id"),
          task_endDate: this.convertDateToString(calendarItem.startDate),
          date: this.convertDateToString(calendarItem.startDate),
          ...this.getFromItem(item)
        };
        if (trackingIndex) {
          let subItem = this.tasksData[id - 1].taskTrackingsTaskId[
            trackingIndex - 1
          ];
          model = {
            ...model,
            task_endDate: _.get(subItem, "task_endDate"),
            date: _.get(subItem, "date"),
            ...this.getFromItem(subItem)
          };
        }
        this.selected = model;
        return;
      }
      this.selected = {
        id: undefined,
        name: undefined,
        description: undefined,
        brandId: undefined,
        dependencyId: undefined,
        docFile: undefined,
        hour: undefined,
        priority: undefined,
        projectId: undefined,
        recurrence: undefined,
        status: undefined,
        _status: null,
        type: undefined,
        startDate: undefined,
        endDate: undefined,
        responsibleId: this.user.id,
        date: undefined,
        completedDate: undefined,
        static: {
          file: null,
          static: undefined
        }
      };
    },

    closeCustomRecurrenceDialog() {
      this.showCustomRecurrence = false;
      this.$nextTick(() => {
        this.customRecurrence = {};
      });
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    closeEditRecurringEvent() {
      this.showEditRecurring = false;
      this.$nextTick(() => {
        this.bodyData = null;
      });
    },
    closeDeleteEvent() {
      this.showDeleteEvent = false;
      this.$nextTick(() => {
        this.deleteStatus = "0";
        this.deletedId = -1;
      });
    },
    onChangeRecurrenceInterval() {
      let val = parseInt(this.customRecurrence.interval);
      if (val <= 0) this.customRecurrence.interval = "1";
    },
    openCustomRecurrenceDialog() {
      this.customRecurrence = {
        freq: "days",
        interval: "1",
        workingDays: [],
        isWeekend: "1",
        day: "1",
        month: "1"
      };
      if (this.selected.recurrence) {
        this.customRecurrence = {
          ...this.customRecurrence,
          ...this.selected.recurrence
        };
      }
      this.showCustomRecurrence = true;
    },
    async onItemEdit(item) {
      await this.createSelectedModel(undefined);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    async onChangePDFFile(file) {
      this.selected.static.file = file;
    },

    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },

    async onChangedProject(projectId) {
      this.dependence_list = null;
      if (!projectId) return;
      let fetch_url = `tasks/getTasks/${projectId}`;
      this.isDependenceLoading = true;

      const resp = await ApiService.get(fetch_url);
      this.dependence_list = resp.data;
      this.dependence_list = this.dependence_list.filter(
        el => el.id !== this.selected.id
      );
      this.isDependenceLoading = false;
    },

    getTextFromFreq(freq) {
      const result = this.FREQ_ITEMS.filter(el => el.value === freq);
      return result[0].text;
    },

    getTextFromWorkingDays(workingDays) {
      let data_list = [];
      this.WORKINGDAYS_ITEMS.forEach(el => {
        if (workingDays.includes(el.value)) data_list.push(el.text);
      });
      return data_list;
    },

    getRecurrenceDescription(recurrence) {
      if (!recurrence) return "";

      let description = "Cada ";
      const frequencyText = this.getTextFromFreq(recurrence.freq);

      if (recurrence.interval === "1") {
        const sliceIndex = recurrence.freq === "months" ? -2 : -1;
        description += frequencyText.slice(0, sliceIndex);
      } else {
        description += `${recurrence.interval} ${frequencyText}`;
      }

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        description += " durante los días laborables";
      } else if (
        recurrence.freq === "weeks" &&
        recurrence.workingDays?.length
      ) {
        const workingDaysText = this.getTextFromWorkingDays(
          recurrence.workingDays
        );
        description += ` en ${workingDaysText.join(", ")}`;
      }

      return description;
    },

    async onSaveCustomRecurrence(item) {
      if (this.$refs.form_custom_recurrence.validate()) {
        this.selected.recurrence = {
          freq: item.freq,
          interval: item.interval
        };
        if (item.freq === "days") {
          this.selected.recurrence = {
            ...this.selected.recurrence,
            isWeekend: item.isWeekend
          };
        }
        if (item.freq === "weeks") {
          this.selected.recurrence = {
            ...this.selected.recurrence,
            workingDays: [...item.workingDays]
          };
        }
        this.showCustomRecurrence = false;
      }
    },

    async onSaveForRecurrency() {
      try {
        this.isRecurrentSaving = true;
        let body = {
          ...this.bodyData,
          recurringStatus: this.recurringStatus
        };
        await ApiService.put(`tasks/${body.id}`, body);
        this.showEdit = false;
        this.showEditRecurring = false;
        this.isRecurrentSaving = false;
        await this.getDataFromApi();
      } catch (error) {
        this.showEdit = false;
        this.showEditRecurring = false;
        this.isRecurrentSaving = false;
      }
    },

    async onDeleteEvent() {
      this.isDeleting = true;
      if (window.confirm("¿Estás seguro de que deseas eliminar este evento?")) {
        try {
          let fetch_url = `tasks/${this.deletedId}/${this.deleteStatus}/${this.selected.date}`;
          await ApiService.delete(fetch_url);
          logInfo("Success");
          this.isDeleting = false;
          this.showDeleteEvent = false;
          this.showEdit = false;
          this.getDataFromApi();
        } catch (error) {
          logError(error);
          this.isDeleting = false;
          this.showDeleteEvent = false;
        }
      }
    },

    async onDeleteClick(item) {
      if (item.type === "Recurrente") {
        this.deletedId = item.id;
        this.showDeleteEvent = true;
        this.deleteStatus = "0";
        return;
      }
      if (window.confirm("¿Estás seguro de que deseas eliminar este evento?")) {
        try {
          this.isDeleting = true;
          let fetch_url = `tasks/${item.id}/-1/-1`;
          await ApiService.delete(fetch_url);
          logInfo("Success");
          this.isDeleting = false;
          this.showEdit = false;
          this.getDataFromApi();
        } catch (error) {
          console.log(error);
          logError(error);
          this.isDeleting = false;
        }
      }
    },

    getProjectDate(projectId) {
      let project_date = { startDate: null, endDate: null };
      this.project_list.forEach(el => {
        if (el.id === projectId) {
          project_date = { startDate: el.startDate, endDate: el.endDate };
        }
      });
      return project_date;
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        if (item.task_startDate && item.task_endDate) {
          if (item.task_startDate > item.task_endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        if (item.projectId) {
          const project_date = this.getProjectDate(item.projectId);
          if (
            item.task_startDate < project_date.startDate ||
            item.task_startDate > project_date.endDate
          ) {
            logError(
              "No puede iniciar la tarea antes de la fecha de inicio del proyecto ni después de la fecha de finalización del proyecto."
            );
            return;
          }
        }
        if (item.type === "Recurrente") {
          if (
            (item.task_startDate &&
              item.task_startDate < item.recurrence_startDate) ||
            (item.task_endDate && item.task_endDate > item.recurrence_endDate)
          ) {
            logError(
              "La fecha de inicio y la fecha de finalización de la tarea deben estar entre la fecha de inicio y la fecha de finalización de la recurrencia."
            );
            return;
          }
        }
        if (item.recurrence_startDate && item.recurrence_endDate) {
          if (item.recurrence_startDate > item.recurrence_endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        if (
          item.type === "Recurrente" &&
          !Object.keys(item.recurrence).length
        ) {
          logError("Recurrencia inválida");
          return;
        }
        let body = _.omit(item, ["static"]);
        try {
          this.isSaving = true;
          let file = item.static.file;
          if (file) {
            let { data } = await ApiService.uploadFile(file);
            body.docFile = data.id;
          }
          let local_date = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          body = {
            ...body,
            assignedBy: this.user.id,
            assignmentDate: local_date
          };
          let completedDate = null;
          if (body.status === "Finalizada") {
            if (body._status !== body.status) completedDate = local_date;
            else completedDate = body.completedDate;
          }
          body = {
            ...body,
            completedDate
          };
          if (body.type !== "Recurrente") {
            body["recurrence"] = undefined;
          }
          if (body.hour === "") body.hour = undefined;
          if (body.id) {
            if (body.type === "Recurrente" && body.date) {
              this.showEditRecurring = true;
              this.bodyData = body;
              this.isSaving = false;
              this.recurringStatus = "0";
              return;
            }
            const resp = await ApiService.put(`tasks/${body.id}`, body);
            if (resp.success) {
              logInfo("tareas actualizada");
            } else {
              logError(resp.message);
            }
          } else {
            const resp = await ApiService.post(`tasks`, body);
            if (resp.success) {
              logInfo("tareas creadas");
            } else {
              logError(resp.message);
            }
          }
          this.showEdit = false;
          this.isSaving = false;
          await this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },
    onChangeFilterMainCredentials(fieldname) {
      if (fieldname === "projects" || fieldname === "company")
        this.searchValues("responsible", "");
    },
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      if (fieldname === "isActive") fieldname = "responsible";
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "projects") {
        this.filtered_projects = resp.data;
      } else if (fieldname === "responsible") {
        this.filtered_responsible = resp.data;
      } else if (fieldname === "company") {
        this.filtered_company = resp.data;
      }
      this.isSearching[fieldname] = false;
    },

    async onClickFilter() {
      if (this.$refs.form_search.validate()) {
        this.getDataFromApi();
      }
    },
    getDateOfPreviousMonth(date) {
      let currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth() - 2);
      const formattedDate = currentDate.toISOString().split("T")[0];
      return formattedDate;
    },
    getDateOfNextMonth(date) {
      let currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth() + 2);
      const formattedDate = currentDate.toISOString().split("T")[0];
      return formattedDate;
    },
    getQueryParams() {
      let previousMonth = this.getDateOfPreviousMonth(this.showDate);
      let nextMonth = this.getDateOfNextMonth(this.showDate);
      let query_params_string = "";
      let query_params = [];
      let filterModel = [];
      query_params.push("monthly_st=" + previousMonth);
      query_params.push("monthly_en=" + nextMonth);

      for (const [key, value] of Object.entries(this.filterMainCredential)) {
        if (value !== "" && value !== null) {
          if (value.length > 0) {
            let filterValue = {};
            value.forEach(el => (filterValue = { ...filterValue, [el]: el }));
            filterModel.push({
              columnField: key,
              filterValue: filterValue
            });
          }
        }
      }
      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "tasks";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tasksData = resp.data;
        this.brand_list = resp.brand_list;
        this.project_list = resp.project_list;
        this.user_list = resp.user_list;
        await this.setCalendarItems();
        this.close();
      } catch (error) {
        this.close();
      }
    },
    getDateFromRecurrence(date, recurrence) {
      if (!recurrence) return null;
      let currentDate = new Date(date);
      let interval = parseInt(recurrence.interval);
      if (recurrence.freq === "days") {
        let isValid = 1;
        let count = 0;
        while (isValid) {
          if (count >= interval) break;
          currentDate.setDate(currentDate.getDate() + 1);
          let day = currentDate.getUTCDay();
          if (recurrence.isWeekend === "1") {
            if (day === 6) {
              currentDate.setDate(currentDate.getDate() + 2);
            } else if (day === 0) {
              currentDate.setDate(currentDate.getDate() + 1);
            }
          }
          count++;
        }
      } else if (recurrence.freq === "weeks") {
        if (recurrence?.workingDays && recurrence.workingDays.length) {
          let isValid = 1;
          while (isValid) {
            currentDate.setDate(currentDate.getDate() + 1);
            let day = currentDate.getUTCDay();
            if (!day) {
              currentDate.setDate(currentDate.getDate() + 7 * interval - 7);
            }
            let item = this.WORKINGDAYS_ITEMS[day];
            if (recurrence.workingDays.includes(item.value)) {
              break;
            }
          }
        } else {
          currentDate.setDate(currentDate.getDate() + 7 * interval);
        }
      } else if (recurrence.freq === "months") {
        currentDate.setMonth(currentDate.getMonth() + interval);
      } else if (recurrence.freq === "years") {
        currentDate.setFullYear(currentDate.getFullYear() + interval);
      }
      return currentDate.toISOString().split("T")[0];
    },

    getTitle(hour, priority, brandName, name) {
      if (!hour) hour = "";
      else hour = hour.slice(0, 5);
      let title = "";
      if (priority === "Alta")
        title = `<span class="triple-exclamation-mark">!!!</span>`;
      if (priority === "Media")
        title = `<span class="second-exclamation-mark">!!</span>`;
      if (priority === "Baja")
        title = `<span class="first-exclamation-mark">!</span>`;
      if (hour) title += `${hour} `;
      title += `${name} ${brandName}`;
      return title;
    },

    getStyleFromStatus(status, completedDate, endDate) {
      let styleType = null;
      if (this.isTaskOnTime(status, completedDate, endDate)) {
        if (status === "Finalizada") styleType = this.STATUS_STYLES.COMPLETED;
        else styleType = this.STATUS_STYLES.PENDING;
      } else {
        if (status === "Finalizada") styleType = this.STATUS_STYLES.OVERDUE;
        else styleType = this.STATUS_STYLES.DELAYED;
      }
      return `background-color: ${styleType}`;
    },

    filterByStatus(status, completedDate, endDate) {
      if (!this.filterMainCredential.status.length) return false;
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let computedStatus;
      if (status === "Finalizada") {
        if (completedDate <= endDate) {
          computedStatus = "Completed";
        } else {
          computedStatus = "Overdue";
        }
      } else if (status === "Pendiente") {
        if (today <= endDate) {
          computedStatus = "Pending";
        } else {
          computedStatus = "Delayed";
        }
      }
      return !this.filterMainCredential.status.includes(computedStatus);
    },

    isDateWithinRecurrence(date, recurrence) {
      if (!recurrence) return true;

      const dayOfWeek = new Date(date).getUTCDay();
      let isValid = true;

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        if (dayOfWeek === 0 || dayOfWeek === 6) isValid = false;
      }
      if (recurrence.freq === "weeks" && recurrence.workingDays?.length) {
        const workingDay = this.WORKINGDAYS_ITEMS[dayOfWeek];
        if (!recurrence.workingDays.includes(workingDay.value)) isValid = false;
      }

      return isValid;
    },

    async setCalendarItems() {
      let data_list = [];
      let count = 0;
      for (let index = 0; index < this.tasksData.length; index++) {
        let task = this.tasksData[index];
        if (task.type === "Recurrente") {
          task.recurrence = JSON.parse(task.recurrence);
        }
        let {
          name,
          priority,
          hour,
          task_startDate,
          task_endDate,
          recurrence_startDate,
          recurrence_endDate,
          status,
          type,
          recurrence,
          taskTrackingsTaskId,
          completedDate
        } = task;
        let brandName = task.brand?.name;

        if (task.type === "Puntual") {
          let eventId =
            (count * this.MULTIPLIER + index + 1) * this.SUB_MULTIPLIER;
          let style = this.getStyleFromStatus(
            status,
            completedDate,
            task_endDate
          );
          let title = this.getTitle(hour, priority, brandName, name);
          if (!this.filterByStatus(status, completedDate, task_endDate)) {
            data_list.push({
              id: eventId,
              title,
              startDate: task_endDate,
              style
            });
            count++;
          }
        } else if (task.type === "Recurrente") {
          let limit = 3000;
          let taskTrackingIndex = 0;
          let nextDate = recurrence_startDate;

          for (let i = 0; i < limit; i++) {
            if (nextDate > recurrence_endDate) break;

            let {
              tempDate,
              style,
              title,
              isRemoved,
              trackingIndex
            } = this.getTaskDetails(task, taskTrackingIndex, nextDate);

            if (
              !isRemoved &&
              this.isDateWithinRecurrence(tempDate, recurrence)
            ) {
              let eventId =
                (count * this.MULTIPLIER + index + 1) * this.SUB_MULTIPLIER +
                (trackingIndex + 1);
              data_list.push({
                id: eventId,
                title,
                startDate: tempDate,
                style
              });
              count++;
            }

            taskTrackingIndex = this.updateTaskTrackingIndex(
              taskTrackingIndex,
              taskTrackingsTaskId,
              nextDate
            );
            nextDate = this.getDateFromRecurrence(nextDate, recurrence);
          }
        }
      }

      this.calendarItems = [...data_list];
    },

    getFromItem(item) {
      let model = {
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        brandId: _.get(item, "brandId"),
        dependencyId: _.get(item, "dependencyId"),
        docFile: _.get(item, "docFile"),
        hour: _.get(item, "hour"),
        priority: _.get(item, "priority"),
        projectId: _.get(item, "projectId"),
        recurrence: _.get(item, "recurrence"),
        status: _.get(item, "status"),
        _status: _.get(item, "status"),
        type: _.get(item, "type"),
        task_startDate: _.get(item, "task_startDate"),
        recurrence_startDate: _.get(item, "recurrence_startDate"),
        recurrence_endDate: _.get(item, "recurrence_endDate"),
        responsibleId: _.get(item, "responsibleId"),
        static: {
          file: null,
          static: _.get(item, "static")
        },
        completedDate: _.get(item, "completedDate")
      };
      return model;
    },

    getTaskDetails(task, taskTrackingIndex, nextDate) {
      let {
        taskTrackingsTaskId,
        name,
        priority,
        hour,
        status,
        completedDate,
        task_endDate,
        brand
      } = task;
      let tempDate = nextDate;
      let style = this.getStyleFromStatus(status, completedDate, tempDate);
      let isRemoved = this.filterByStatus(status, completedDate, tempDate);
      let title = this.getTitle(hour, priority, brand?.name, name);
      let trackingIndex = -1;

      if (
        taskTrackingIndex < taskTrackingsTaskId.length &&
        nextDate === taskTrackingsTaskId[taskTrackingIndex].date
      ) {
        let tracking = taskTrackingsTaskId[taskTrackingIndex];
        if (tracking.type === "Recurrente") {
          tracking.recurrence = JSON.parse(tracking.recurrence);
        }
        tempDate = tracking.task_endDate;
        style = this.getStyleFromStatus(
          tracking.status,
          tracking.completedDate,
          tracking.task_endDate
        );
        isRemoved = this.filterByStatus(
          tracking.status,
          tracking.completedDate,
          tracking.task_endDate
        );
        title = this.getTitle(
          tracking.hour,
          tracking.priority,
          tracking.brand?.name,
          tracking.name
        );

        if (tracking.isRemoved) {
          isRemoved = true;
        }
        trackingIndex = taskTrackingIndex;
      }

      return {
        tempDate,
        style,
        title,
        isRemoved,
        trackingIndex
      };
    },

    updateTaskTrackingIndex(currentIndex, taskTrackingsTaskId, nextDate) {
      if (
        currentIndex < taskTrackingsTaskId.length &&
        nextDate === taskTrackingsTaskId[currentIndex].date
      ) {
        return currentIndex + 1;
      }
      return currentIndex;
    },

    init() {
      this.searchValues("brands", "");
      this.searchValues("projects", "");
      this.searchValues("responsible", "");
      this.searchValues("company", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>
<style>
.calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #000;
  height: 75vh;
  max-width: 97%;
  margin: 0 auto 20px;
}
.calendar .cv-weeks .cv-day {
  flex-flow: column;
  font-size: 0.9em;
}
.calendar .cv-weeks .cv-day.today {
  background-color: #ccc;
}
.calendar .cv-weeks .cv-day.today .cv-day-number {
  margin-top: 0.25rem;
  background-color: rgb(26, 115, 232);
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 21px;
  text-align: center;
}
.calendar .cv-weeks .cv-day .cv-day-number {
  align-self: center;
}
.theme-default .cv-event {
  font-size: 0.9em;
  font-weight: 550 !important;
  color: #fff;
  padding-left: 7px;
  cursor: pointer;
  border-radius: 0.3em;
}
.theme-default .cv-event:hover {
  opacity: 0.7;
}
.triple-exclamation-mark {
  background-color: #ed2b2b;
  font-size: 12px;
  font-weight: bold !important;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
  padding: 1px 2px;
}
.second-exclamation-mark {
  background-color: #eded1dba;
  font-size: 12px;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
  padding: 1px 3px;
  letter-spacing: 0.8px;
}
.first-exclamation-mark {
  background-color: #349d34;
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
}
</style>
